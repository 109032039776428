import React, { useContext, useMemo } from "react";
import { ImageAvatar } from "@metyis-ds/avatar";
import { PrimaryButton } from "@metyis-ds/button";
import ContentContext, { IContent } from "../contexts/ContentContext";
import { IArticle } from "../components/article/types";
import { IProfile } from "../components/profile/types";
const getOptimizedImgUrl = require("../helpers/helpers.ts").getOptimizedImgUrl;
import ArticleList from "../components/article/ArticleList";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import CTABanner from "../components/banner/CTABanner";
import Page from "../components/page/Page";
import RedirectBanner from "../components/banner/RedirectBanner";
import "./profile.css";
import { Link } from "../components/Link/LinkWithPrevUrl";
import Linkedin from "../assets/linkedin.svg";

export default ({
  pageContext
}: {
  pageContext: { articles: IArticle[]; content: IContent; profile: IProfile };
}) => {
  const { content: savedContent } = useContext(ContentContext);
  const { articles, content, profile } = pageContext;
  const {
    id,
    avatar,
    contact,
    coworkerLocation,
    firstName,
    jobPosition,
    name,
    shortBio,
    linkedin
  } = profile;
  const articlesByAuthor = useMemo(() => {
    return articles?.filter(a =>
      a.authors?.find((aut: IProfile) => aut.id === id)
    );
  }, [savedContent, id]);

  const handleClickContact = () => {
    if (
      !!contact &&
      typeof window !== "undefined" &&
      !!window?.location?.href
    ) {
      window.location.href = `mailto:${contact}`;
    }
  };

  return (
    <Page
      content={content}
      seo={{
        description:
          shortBio.shortBio.length > 200
            ? `${shortBio.shortBio.substring(0, 141)}...`
            : shortBio.shortBio,
        image: avatar.file.url,
        title: name
      }}
    >
      <BreadCrumbs
        crumbsArray={[
          {
            name: "About",
            redirectUrl: "/about"
          },
          {
            name: "Senior leaders",
            redirectUrl: "/profiles"
          },
          {
            name: name
          }
        ]}
      />
      <section className="profile">
        <div className="profile-details-wrapper">
          <div className="profile-details">
            <ImageAvatar
              className="profile-img"
              fallbackText={name[0]}
              img={{
                alt: avatar.title,
                className: "lazyload",
                "data-src": getOptimizedImgUrl({
                  url: avatar.file.url,
                  useFallbackFormat: true,
                  width: 200
                })
              }}
              size="xxl"
            />
            <span className="profile-name">{name}</span>
            <span className="profile-title">
              {`${jobPosition}, ${coworkerLocation}`}
            </span>
            {!!contact && (
              <div className="profile-buttons">
                <PrimaryButton
                  backgroundColor="var(--color-blue-dark)"
                  className="profile-contact"
                  color="var(--color-white)"
                  onClick={handleClickContact}
                >
                  Contact {firstName}
                </PrimaryButton>
                {linkedin && (
                  <>
                    <Link className="linkedin-wrapper" to={linkedin}>
                      <Linkedin />
                      <p className="linkedin-button-text">View on LinkedIn</p>
                    </Link>
                    <div className="underline"></div>
                  </>
                )}
              </div>
            )}
          </div>
          {shortBio && <span className="profile-bio">{shortBio.shortBio}</span>}
        </div>
        <RedirectBanner
          backgroundColor="var(--color-blue-dark)"
          textColor="var(--color-brown)"
          title="Meet all senior leaders"
          redirectUrl={"/profiles"}
        />
        {!!articlesByAuthor?.length && (
          <>
            <ArticleList
              list={articlesByAuthor}
              title="Articles by"
              title2={name}
              titleColor="#bbb"
              type="Thumbnail"
            />
            <RedirectBanner title="Read more insights" redirectUrl="/impact" />
          </>
        )}

        <CTABanner
          backgroundColor="var(--color-yellow)"
          image={{
            file: {
              url:
                "https://images.ctfassets.net/zuzzsqg9enty/1fa09TeC2ZT1bGVdR65zkf/22312151f90eb0c8b07a5d2be3965030/01-Careers.png"
            },
            title: "Careers stock photo"
          }}
          redirectUrl="/careers"
          text={{
            text:
              "We are always on the lookout for talented individuals to join our growing teams. Whether you are a recent graduate, postdoc or an experienced professional."
          }}
          textColor="var(--color-blue-dark)"
          title="Welcome to the next stage of your Career"
        />
      </section>
    </Page>
  );
};
